import axios from "@/axios.js";

export default {
  addItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      debugger;
      axios
        .post("API/UnitType/AddNewUintType", item,{headers:Token})
        .then(response => {
          debugger;
          resolve(response);
          commit(
            "ADD_ITEM", Object.assign(item, { ID: response.data.ID })
          );
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  fetchDataListItems({ commit }) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};

    debugger;
    return new Promise((resolve, reject) => {
      axios
        .get("API/UnitType/GetAllUintTypes",{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_UnitType", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  updateItem({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .put("API/UnitType/UpdateUintType", item)
        .then(response => {
          resolve(response);
          commit("UPDATE_UnitType", item);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  GetItemByID(context, itemid) {
    return new Promise((resolve, reject) => {
      debugger;
      axios
      .get("API/UnitType/GetUintTypeById?Id=" + itemid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  DeleteObject({ commit }, item) {
    debugger;
    return new Promise((resolve, reject) => {
      axios
        .delete("API/UnitType/DeleteUintType?Id=" + item.id)
        .then(response => {
          resolve(response);
          commit("REMOVE_ITEM", item.id);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  UploadImage(context, image) {
    const formData = new FormData();
    formData.append("file",image, image.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};
