export default {
  ADD_ITEM(state, item) {
    state.UnitTypes.unshift(item);
  },
  SET_UnitType(state,  UnitTypes) {
    state.UnitTypes =  UnitTypes;
  },
  UPDATE_UnitType(state,  UnitType) {
    debugger;
    const  UnitTypesIndex = state.UnitTypes.findIndex(p => p.id ==  UnitType.Id);
    if (UnitTypesIndex != -1) {
      Object.assign(state.UnitTypes[UnitTypesIndex],  UnitType);
    }
  },
  REMOVE_ITEM(state, itemId) {
    const ItemIndex = state.UnitTypes.findIndex(p => p.ID == itemId);
    state.UnitTypes.splice(ItemIndex, 1);
  }
};

