<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
    class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">

    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? $t('AddNew') : $t('Update') }} {{ $t('Item') }}</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <vs-card title="icon">
        <h4>{{ $t('NameAr') }}</h4>
        <vs-input class="w-full" v-model="NameAR" v-validate="'required'" name="NameAR" />
        <h4>{{ $t('NameEn') }}</h4>
        <vs-input class="w-full" v-model="NameEN" v-validate="'required'" name="NameEN" />
      </vs-card>

    </VuePerfectScrollbar>

    <div class="flex flex-wrap items-center p-6" slot="footer">

      &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp;
            <vs-button
            style="font-size: 16px;font-family:almarai ;color: green; width:90px ;"
              type="border"

              icon-pack="feather"
              icon="icon-plus"
              @click="submitData"
              >{{ $t('Save') }}</vs-button
            >
            &nbsp; &nbsp; &nbsp;

      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{ $t('Cancel') }}</vs-button>
    </div>

  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";

import moduleUnitType from "@/store/UnitType/moduleUnitType.js";

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },

  },
  data() {
    return {
      UnitTypeOptions: [],
      Id: null,
      //ShowOffersWithNoRestrict: false,
      NameAR: '',
      NameEN: '',
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },

  watch: {
    isSidebarActive(val) {
      debugger;
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        let { id, NameAR, NameEN } = JSON.parse(
          JSON.stringify(this.data)
        );
        this.Id = id;
        this.NameAR = NameAR;
        this.NameEN = NameEN;


      }

      console.log("Id isSidebarActive:" + this.Id );

    }
  },


  computed: {
    isSidebarActiveLocal: {

      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },

    isFormValid() {
      return !this.errors.any() && this.Id;
    }
  },

  methods: {
    // GetData() {
    //   this.$store.dispatch("UintTypeList/fetchDataListItems").then((res) => {
    //     debugger;
    //     this.UnitTypeOptions = res.data.data;
    //   });
    // },
    initValues() {
      if (this.data.id) return;
      this.Id = null;
     // this.ShowOffersWithNoRestrict = false;
      this.NameAR = '';
      this.NameEN = '';
    },

     submitData() {
      debugger;
      try {
        debugger;
        const result =  this.$validator.validateAll();
        if (result) {
          const obj = {
            Id: this.Id,
            NameAR: this.NameAR,
            NameEN: this.NameEN,
          };
        //  console.log("Id f:" + obj.Id);

          debugger;
          if (this.Id != null && this.Id > 0) {
            debugger;

            this.$store.dispatch("UintTypeList/updateItem", obj).then(() => {
                this.$store.dispatch("UintTypeList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });

          } else {
           // console.log("Id:" +obj.Id);
            delete obj.Id;
            obj.popularity = 0;
            this.$store.dispatch("UintTypeList/addItem", obj).then(() => {
                this.$store.dispatch("UintTypeList/fetchDataListItems");
                window.showSuccess();
              })
              .catch((err) => {
                console.error(err);
              });
          }

          //window.showSuccess();
          this.$emit("closeSidebar");
          this.initValues();
        }
      } catch (error) {
        console.error(error);
      }
    },



  },

  components: {
    VuePerfectScrollbar
  },
  create() {
    if (!moduleUnitType.isRegistered) {
      this.$store.registerModule("UintTypeList", moduleUnitType);
      moduleUnitType.isRegistered = true;
    }

    this.$store.dispatch("UintTypeList/fetchDataListItems");
   },

};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
